* {
  box-sizing: border-box;
  font-family: 'Noto Sans TC', sans-serif;
  font-weight: 400;
  p {
    color: #2E3D56;
    font-size: 16px;
    line-height: 28px;
  }
}


div.App {
  margin: 0 auto;
  width: 640px ;
  max-height: 979px;
  background: #FFFFFF ;
  border: 1px solid #E7E7E7;
  border-radius: 24px;
  overflow: hidden; 
  header {
    height: 90px;
    .headline {
      height: 10px;
      background: #215EC6;
      border-radius: 24px 24px 0 0;
    }
    img {
      height: 30px;
      margin-top: 20px;
      margin-left: 30px;
    }
  }
  main {
    max-height: 833px;
    padding: 20px 70px;
    section.title {
      display: flex;
      justify-content: center;
      align-items: center;
      p {
        line-height: 31.86px;
        font-weight: 700;
        font-size: 22px;
        text-align: center;
      }
    }
     section.content {
      div.period {
       display: flex;
       justify-content: center;
       align-items: center;
       flex-direction: column;
        p {
          text-align: center;
        }
        span {
          font-weight: 700;
          font-size: 20px;
          color: #215EC6 ;
          line-height: 28.96px;
        }
      }
      div.contact {
        padding: 50px 70px 20px 70px;
        display: flex;
        justify-content: center;
        align-items: center;
        p {
          line-height: 17.38px;
          font-size: 12px;
          text-align: center;
          color:#808C9F;
        }
        a {
          text-decoration: none;
          color: #215EC6;
          font-size: 12px;
        }
      }
     }
  }
  footer {
    max-height: 115px;
    padding: 20px 70px 30px 70px;
    background: #FCFCFC;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    p {
      font-size: 16px;
    }
    span {
      color: #808C9F;
      font-size: 12px;
      line-height: 17.38px;
      text-align: center;
    }
  }
}

@media (max-width: 360px) {
  div.App {
    width: 360px;
    max-height: none;
    main {
      max-height: 967px;
      padding: 20px 30px;
    }
    footer {
      max-height: 132px;
      padding: 20px 70px 30px 70px;
      
    }
  }
}
